html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: #604A8E;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  min-width: 280px;
  height: 100%;
}

.v-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0)
}

a, button {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
