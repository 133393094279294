@use "sass:math";

.card {
  position: relative;
  display: block;
  width: 100%;
  min-width: 280px;
  height: 100%;
  margin: 0 auto;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;

  @media screen and (orientation: landscape) {
    max-width: calc(#{$CANVAS_WIDTH} / #{$CANVAS_HEIGHT} * 100vh);
  }

  &_ru {
    background-image: url("./images/bg-ru.jpg");
    background-position: 50% 50%;
    &::before,
    &::after {
      display: none;
    }
  }
  &_uz {
    background-image: url("./images/bg-multi.jpg");
    &::after {
      background-image: url("./images/text-uz.svg");
    }
  }
  &_mn {
    background-image: url("./images/bg-multi.jpg");
    &::after {
      background-image: url("./images/text-mn.svg");
    }
  }
  &_kz {
    background-image: url("./images/bg-multi.jpg");
    &::after {
      background-image: url("./images/text-kz.svg");
    }
  }
  &_kg {
    background-image: url("./images/bg-multi.jpg");
    &::after {
      background-image: url("./images/text-kg.svg");
    }
  }
  &_ge {
    background-image: url("./images/bg-multi.jpg");
    &::after {
      background-image: url("./images/text-ge.svg");
    }
  }
  &_az {
    background-image: url("./images/bg-multi.jpg");
    &::after {
      background-image: url("./images/text-az.svg");
    }
  }
  &_am {
    background-image: url("./images/bg-multi.jpg");
    &::after {
      background-image: url("./images/text-am.svg");
    }
  }

  &::before{
    content:"";
    position: absolute;
    top: height(50);
    left: width(50);
    display: block;
    width: width(202);
    height: 0;
    padding-top: width(102);
    background-image: url("./images/logo.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  &::after {
    content:"";
    position: absolute;
    top: height(230);
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 0;
    padding-top: width(440);
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__link {
    position: absolute;
    left: 0;
    right: 0;
    bottom: height(70);
    display: block;
    width: width(731);
    height: height(153);
    margin: 0 auto;
    text-decoration: none;
    background: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;

    &_ru {
      background-image: url("./images/btn-ru-normal.svg");
    }
  }
}
