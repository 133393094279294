@use "sass:math";

.sound {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  min-width: 280px;
  height: 100%;
  margin: 0 auto;
  pointer-events: none;
  z-index: 0;

  @media screen and (orientation: landscape) {
    max-width: calc(#{$CANVAS_WIDTH} / #{$CANVAS_HEIGHT} * 100vh);
  }

  &__toggle {
    position: absolute;
    left: width(24);
    bottom: height(24);
    display: block;
    width: width(152);
    height: 0;
    padding-top: width(152);
    border: none;
    border-radius: 50%;
    background-color: transparent;
    pointer-events: all;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("./images/sound_on.svg");
      opacity: 1;
      visibility: visible;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("./images/sound_off.svg");
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 0;
      visibility: hidden;
    }

    &[aria-pressed="true"] {
      &::before {
        opacity: 0;
        visibility: hidden;
      }

      &::after {
        opacity: 1;
        visibility: visible;
      }
    }

    &[hidden] {
      display: none;
    }
  }

  &__popup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparentize(#000000, .7);

    &[hidden] {
      display: none;
    }
  }

  &__alert {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: width(799);
    height: 0;
    padding-top: width(526);
    transform: translate(-50%, -50%);
    background-image: url("./images/alert.png");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__button {
    position: absolute;
    left: width(228, 799);
    bottom: height(40, 526);
    display: block;
    width: width(343, 799);
    height: height(143, 526);
    padding: 0;
    border: none;
    background-color: transparent;
    background-image: url("./images/btn-ok-normal.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: all;
    cursor: pointer;
  }
}
