@use "sass:math";

.countries {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 280px;
  height: 100%;
  margin: 0 auto;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./images/bg.jpg");
  z-index: 0;

  @media screen and (orientation: landscape) {
    max-width: calc(#{$CANVAS_WIDTH} / #{$CANVAS_HEIGHT} * 100vh);
  }

  &__logo {
    position: relative;
    display: block;
    justify-self: flex-start;
    width: width(595);
    height: height(143);
    margin-top: auto;
    margin-bottom: auto;
    text-decoration: none;
    background: transparent;
    background-image: url("./images/logo.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
  }

  &__link {
    position: relative;
    display: block;
    width: width(476);
    height: height(109);
    text-decoration: none;
    background: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;

    &_ru {
      background-image: url("./images/btn-ru-normal.svg");
    }

    &_kz {
      background-image: url("./images/btn-kz-normal.svg");
    }

    &_ua {
      background-image: url("./images/btn-ua-normal.svg");
    }

    &_ge {
      background-image: url("./images/btn-ge-normal.svg");
    }

    &_az {
      background-image: url("./images/btn-az-normal.svg");
    }

    &_uz {
      background-image: url("./images/btn-uz-normal.svg");
    }

    &_mn {
      background-image: url("./images/btn-mn-normal.svg");
    }

    &_kg {
      background-image: url("./images/btn-kg-normal.svg");
    }

    &_am {
      background-image: url("./images/btn-am-normal.svg");
      margin-bottom: auto;
    }

    & + & {
      margin-top: height(40);
    }

    span {
      position: absolute;
      color: transparent;
    }
  }
}
